.bccampus-competency-box {
  width   : 100%;
  height  : 100%;
  overflow: hidden;

  display    : flex;
  align-items: center;
  gap        : 0.5em;
  padding    : 0.5em;
}

.bccampus-competency-box .bccampus-link.button.secondary {
  width          : 100%;
  height         : 100%;
  border         : solid 4px var(--primary-color);
  display        : flex;
  align-items    : center;
  justify-content: center;
}

.bccampus-competency-box .bccampus-link span {
  height: auto;
}

.bccampus-competency-box .bccampus-competency-box-icon {
  width     : 5rem;
  text-align: center;
}
